import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
// import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider'
// import { InputNumber } from 'primereact/inputnumber';
import { ExportExcel } from '../../utils/exporters'

import DefaultDataTable from 'react-data-table-component';
import { Fragment, useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
import "../../style/ar-tables.css"
// import { current } from '@reduxjs/toolkit';

export function DefaultTable(props) {

    const { columns, data } = props
    // const data = useSelector(state => state.data.data[selector])

    return (
        <DefaultDataTable columns={columns} data={data} striped />
    )
}

export function DataViewTable({ data, onChange, columnList, scrollHeight, customColumn }) {
    // console.log(data)
    // const [dataTable, setDataTable] = useState(data);
    const currentData = useRef(data)
    const dt = useRef(null);

    const [filters, setFilters] = useState({
        CLIENT: { value: null, matchMode: FilterMatchMode.IN },
        CLIENT_NAME: { value: null, matchMode: FilterMatchMode.IN },
        CODE: { value: null, matchMode: FilterMatchMode.IN },
        CLIENT_PARTNER: { value: null, matchMode: FilterMatchMode.IN },
        CLIENT_MANAGER: { value: null, matchMode: FilterMatchMode.IN },
        JOB_NAME: { value: null, matchMode: FilterMatchMode.IN },
        JOB_PARTNER: { value: null, matchMode: FilterMatchMode.IN },
        JOB_MANAGER: { value: null, matchMode: FilterMatchMode.IN },
        OFFICE: { value: null, matchMode: FilterMatchMode.IN },
        AGING_PERIOD: { value: null, matchMode: FilterMatchMode.IN },
        OUTSTANDING: { value: null, matchMode: FilterMatchMode.BETWEEN },
        SUBMIT_EMPLOYEE: { value: null, matchMode: FilterMatchMode.IN },
        REVIEW_EMPLOYEE: { value: null, matchMode: FilterMatchMode.IN },
        REQUEST_EMPLOYEE: { value: null, matchMode: FilterMatchMode.IN },
        SUBMIT_HOMEROOM: { value: null, matchMode: FilterMatchMode.IN },
        REVIEW_HOMEROOM: { value: null, matchMode: FilterMatchMode.IN },
        REQUEST_HOMEROOM: { value: null, matchMode: FilterMatchMode.IN },
        RATING: { value: null, matchMode: FilterMatchMode.IN },
        DIRECTION: { value: null, matchMode: FilterMatchMode.IN },
        STAFFNAME: { value: null, matchMode: FilterMatchMode.IN },
        STAFF_NAME: { value: null, matchMode: FilterMatchMode.IN },
        EMPLOYEE: { value: null, matchMode: FilterMatchMode.IN },
        LEVEL: { value: null, matchMode: FilterMatchMode.IN },
        DEPARTMENT: { value: null, matchMode: FilterMatchMode.IN },
        HOMEROOM: { value: null, matchMode: FilterMatchMode.IN },
        HOMEROOM_NAME: { value: null, matchMode: FilterMatchMode.IN},
        CHECKER_NAME: { value: null, matchMode: FilterMatchMode.IN},
        ADMIN: { value: null, matchMode: FilterMatchMode.IN },
        PERSONAL: { value: null, matchMode: FilterMatchMode.IN },
        SCHEDULE: { value: null, matchMode: FilterMatchMode.IN },
        TEAM: { value: null, matchMode: FilterMatchMode.IN },
        WEEK: { value: null, matchMode: FilterMatchMode.IN },
        REQUEST_STATUS: { value: null, matchMode: FilterMatchMode.IN },
        JOB_STATUS: { value: null, matchMode: FilterMatchMode.IN },
        STAFF_TYPE: { value: null, matchMode: FilterMatchMode.IN },
        COMPLIANT_PERCENT: { value: null, matchMode: FilterMatchMode.BETWEEN },
        COMPLIANT_RANGE: { value: null, matchMode: FilterMatchMode.IN },
        QUAD_GOAL: { value: null, matchMode: FilterMatchMode.IN },
        MET_CURR_GOAL: { value: null, matchMode: FilterMatchMode.IN },
        CURRENT_QUAD_ROLOS: { value: null, matchMode: FilterMatchMode.IN },
        PREVIOUS_GOAL: { value: null, matchMode: FilterMatchMode.IN },
        MET_PREV_GOAL: { value: null, matchMode: FilterMatchMode.IN },
        PREV_QUAD_ROLOS: { value: null, matchMode: FilterMatchMode.IN },
        STAFFLEVEL: { value: null, matchMode: FilterMatchMode.IN },
    })

    // useEffect(()=>{
    // 	onChange(dataTable);
    // }, [onChange, dataTable])


    // const filterData = useCallback((value) => {
    // 		// setDataTable(value)
    // 		onChange(dataTable);
    // }, [dataTable, onChange])

    const outstandingTemplate = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const toTitleCase = value => {
        return value.toLowerCase().split(' ').map(word => {
            return (word.charAt(0).toUpperCase() + word.slice(1));
          }).join(' ')
    }

    const multiselectTemplate = (options) => {
        // console.log("The options outputs: ", options)
        let optionsList = [];
        let placeholder = "";
        switch (options.field) {
            case "OFFICE":
                optionsList = columnList['OFFICE'];
                placeholder = "Office";
                break;
            case "AGING_PERIOD":
                placeholder = "Aging Period"
                optionsList = columnList['AGING_PERIOD'];
                break;
            default:
                break;
        }
        return (
            <MultiSelect
                filter
                value={options.value}
                options={optionsList}
                className="column-filter"
                onChange={(e) => options.filterApplyCallback(e.value)}
                display="chip"
                placeholder={placeholder}
                showSelectAll="false"
            />
        )
    }

    const multiFilterTemplate = (options) => {
        // console.log(options);
        let optionsList = [];
        let placeholder = "";
        switch (options.field) {
            case "CLIENT":
                optionsList = columnList['CLIENT'];
                placeholder = "Client";
                break;
            case "CLIENT_NAME":
                optionsList = columnList['CLIENT_NAME'];
                placeholder = "Client";
                break;
            case "CLIENT_MANAGER":
                optionsList = columnList['CLIENT_MANAGER'];
                placeholder = "Client Manager";
                break;
            case "CLIENT_PARTNER":
                optionsList = columnList['CLIENT_PARTNER'];
                placeholder = "Client Partner"
                break;
            case "JOB_NAME":
                optionsList = columnList['JOB_NAME'];
                placeholder = "Job";
                break;
            case "JOB_MANAGER":
                optionsList = columnList['JOB_MANAGER'];
                placeholder = "Job Manager";
                break;
            case "JOB_PARTNER":
                optionsList = columnList['JOB_PARTNER'];
                placeholder = "Job Partner"
                break;
            case "CODE":
                optionsList = columnList['CODE'];
                placeholder = "Code"
                break;
            case "SUBMIT_EMPLOYEE":
                optionsList = columnList['SUBMIT_EMPLOYEE']
                placeholder = 'Sender'
                break;
            case "SUBMIT_HOMEROOM":
                optionsList = columnList['SUBMIT_HOMEROOM']
                placeholder = 'Sender Homeroom'
                break;
            case "REVIEW_EMPLOYEE":
                optionsList = columnList['REVIEW_EMPLOYEE']
                placeholder = 'Recipient'
                break;
            case "REVIEW_HOMEROOM":
                optionsList = columnList['REVIEW_HOMEROOM']
                placeholder = 'Recipient Homeroom'
                break;
            case "REQUEST_EMPLOYEE":
                optionsList = columnList['REQUEST_EMPLOYEE']
                placeholder = 'Recipient'
                break;
            case "REQUEST_HOMEROOM":
                optionsList = columnList['REQUEST_HOMEROOM']
                placeholder = 'Recipient Homeroom'
                break;
            case "RATING":
                optionsList = columnList['RATING']
                placeholder = 'Rating'
                break;
            case "DIRECTION":
                optionsList = columnList['DIRECTION']
                placeholder = 'Direction'
                break;
            case "ADMIN":
                optionsList = columnList['ADMIN']
                placeholder = 'Admin load manageable'
                break;
            case "TEAM":
                optionsList = columnList['TEAM']
                placeholder = 'Enjoy my team'
                break;
            case "SCHEDULE":
                optionsList = columnList['SCHEDULE']
                placeholder = 'Schedule is reasonable'
                break;
            case "PERSONAL":
                optionsList = columnList['PERSONAL']
                placeholder = 'Personal distraction'
                break;
            case "STAFFNAME":
                optionsList = columnList['STAFFNAME']
                placeholder = 'Staff Name'
                break;
            case "STAFF_NAME":
                optionsList = columnList['STAFF_NAME']
                placeholder = 'Staff Name'
                break;
            case "EMPLOYEE":
                optionsList = columnList['EMPLOYEE']
                placeholder = 'Staff Name'
                break;
            case "LEVEL":
                optionsList = columnList['LEVEL']
                placeholder = 'Staff Level'
                break;
            case "DEPARTMENT":
                optionsList = columnList['DEPARTMENT']
                placeholder = 'Staff Department'
                break;
            case "HOMEROOM":
                optionsList = columnList['HOMEROOM']
                placeholder = 'Staff Homeroom'
                break;
            case "HOMEROOM_NAME":
                optionsList = columnList['HOMEROOM_NAME']
                placeholder = 'Staff Homeroom'
                break;
            case "CHECKER_NAME":
                optionsList = columnList['CHECKER_NAME']
                placeholder = 'Homeroom Checker'
                break;
            case 'WEEK':
                optionsList = columnList['WEEK']
                placeholder = 'Week'
                break;
            case 'JOB_STATUS':
                optionsList = columnList['JOB_STATUS']
                placeholder = 'Job Status'
                break;
            case 'STAFF_TYPE':
                optionsList = columnList['STAFF_TYPE']
                placeholder = 'Staff Type'
                break;
            case 'REQUEST_STATUS':
                optionsList = columnList['REQUEST_STATUS']
                placeholder = 'Request Status'
                break;
            case 'COMPLIANT_RANGE':
                optionsList = columnList['COMPLIANT_RANGE']
                placeholder = 'Compliance Range'
                break;
            // case 'COMPLIANT_PERCENT':
            //     optionsList = columnList['COMPLIANT_PERCENT']
            //     placeholder = 'Compliant Percent'
            //     break;
            default:
                break;
        }
        return (
            <span>
                <MultiSelect
                    value={options.value}
                    options={optionsList}
                    onChange={(e) => options.filterApplyCallback(e.value)}
                    className="lg-column-filter column-filter w-full"
                    placeholder={placeholder}
                    display="chip"
                    filter
                />
            </span>
        )
    }


    const sliderFilterTemplate = (options) => {
        console.log(options)
        return (
            <Fragment>
                <Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
                <div className="flex align-items-center justify-content-between px-2">
                    <span>{options.value ? options.value[0] : 0}</span>
                    <span>{options.value ? options.value[1] : 100}</span>
                </div>
            </Fragment>
        )
    };

    // const moneyRangeFilterTemplate = (options) => {
    //     return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
    // };

    // const clientBodyTemplate = (rowData) => {
    //     return (
    //         <span>{rowData.CLIENT}</span>
    //     )
    // };

    const saveSheet = () => {
        ExportExcel(currentData.current);
    };

    const paginatorRight = () => {
        return <Button type="button" icon="pi pi-download" onClick={saveSheet} data-pr-tooltip="XLS" text />;
    };

    function* renderSwitch(list) {
        for (let item in list) {
            switch (item) {
                case "CLIENT":
                    yield (
                        <Column
                            key="CLIENT"
                            className="lg-column column"
                            header="Client"
                            field="CLIENT"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CLIENT'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "CLIENT_NAME":
                    yield (
                        <Column
                            key="CLIENT_NAME"
                            className="md-column column"
                            header="Client"
                            field="CLIENT_NAME"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CLIENT_NAME'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                            style={{ textWrap: 'wrap' }}
                        ></Column>
                    );
                    break;
                case "CODE":
                    yield (
                        <Column
                            key="CODE"
                            className="sm-column column"
                            header="Code"
                            field="CODE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CODE'
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;

                case "CLIENT_MANAGER":
                    yield (
                        <Column
                            key="CLIENT_MANAGER"
                            className="md-column column"
                            header="Client Manager"
                            field="CLIENT_MANAGER"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CLIENT_MANAGER'
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "CLIENT_PARTNER":
                    yield (
                        <Column
                            key="CLIENT_PARTNER"
                            className="md-column column"
                            field="CLIENT_PARTNER"
                            header="Client Partner" sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CLIENT_PARTNER'
                            filterElement={multiFilterTemplate}>
                        </Column>
                    );
                    break;
                case "JOB_NAME":
                    yield (
                        <Column
                            key="JOB_NAME"
                            className="md-column column"
                            header="Job"
                            field="JOB_NAME"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='JOB_NAME'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                            style={{ textWrap: 'wrap' }}
                        ></Column>
                    );
                    break;
                case "JOB_MANAGER":
                    yield (
                        <Column
                            key="JOB_MANAGER"
                            className="md-column column"
                            header="Job Manager"
                            field="JOB_MANAGER"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='JOB_MANAGER'
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "JOB_PARTNER":
                    yield (
                        <Column
                            key="JOB_PARTNER"
                            className="md-column column"
                            field="JOB_PARTNER"
                            header="Job Partner" sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='JOB_PARTNER'
                            filterElement={multiFilterTemplate}>
                        </Column>
                    );
                    break;
                case "OFFICE":
                    yield (
                        <Column
                            key="OFFICE"
                            className="sm-column column"
                            field="OFFICE"
                            header="Office" sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='OFFICE'
                            filterMatchMode="in"
                            filterElement={multiselectTemplate}>
                        </Column>
                    );
                    break;
                case "AGING_PERIOD":
                    yield (
                        <Column
                            key="AGING_PERIOD"
                            className="sm-column column"
                            field="AGING_PERIOD"
                            header="Aging Period" sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='AGING_PERIOD'
                            filterMatchMode="in"
                            filterElement={multiselectTemplate}>
                        </Column>
                    );
                    break;
                case "OUTSTANDING":
                    yield (
                        <Column
                            key="OUTSTANDING"
                            className="md-column column"
                            field="OUTSTANDING"
                            filterField="OUTSTANDING"
                            header="Outstanding"
                            sortable body={outstandingTemplate}>
                        </Column>
                    );
                    break;
                case "WIPOUTSTANDING":
                    yield (
                        <Column
                            key="OUTSTANDING"
                            className="md-column column"
                            dataType="numeric"
                            field="OUTSTANDING"
                            // filter
                            showFilterMatchModes={false}
                            showFilterMenu={true}
                            filterClear="false"
                            filterField="OUTSTANDING"
                            filterElement={sliderFilterTemplate}
                            header="Outstanding"
                            sortable
                            body={value => outstandingTemplate(value.WIPOUTSTANDING)}>
                        </Column>
                    );
                    break;
                case "OUTSTANDING_WIP":
                    yield (
                        <Column
                            key="OUTSTANDING_WIP"
                            className="md-column column"
                            dataType="numeric"
                            field="OUTSTANDING_WIP"
                            // filter
                            showFilterMatchModes={false}
                            showFilterMenu={true}
                            filterClear="false"
                            filterField="OUTSTANDING_WIP"
                            filterElement={sliderFilterTemplate}
                            header="Outstanding WIP"
                            sortable
                            body={value => outstandingTemplate(value.WIPOUTSTANDING)}>
                        </Column>
                    );
                    break;
                case "SUBMIT_EMPLOYEE":
                    yield (
                        <Column
                            key="SUBMIT_EMPLOYEE"
                            className="md-column column"
                            header="Sender Name"
                            field="SUBMIT_EMPLOYEE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='SUBMIT_EMPLOYEE'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "SUBMIT_HOMEROOM":
                    yield (
                        <Column
                            key="SUBMIT_HOMEROOM"
                            className="md-column column"
                            header="Sender Homeroom"
                            field="SUBMIT_HOMEROOM"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='SUBMIT_HOMEROOM'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "REVIEW_EMPLOYEE":
                    yield (
                        <Column
                            key="REVIEW_EMPLOYEE"
                            className="md-column column"
                            header="ROLO Recipient"
                            field="REVIEW_EMPLOYEE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='REVIEW_EMPLOYEE'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "REVIEW_HOMEROOM":
                    yield (
                        <Column
                            key="REVIEW_HOMEROOM"
                            className="md-column column"
                            header="Recipient Homeroom"
                            field="REVIEW_HOMEROOM"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='REVIEW_HOMEROOM'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "REQUEST_EMPLOYEE":
                    yield (
                        <Column
                            key="REQUEST_EMPLOYEE"
                            className="md-column column"
                            header="Request Recipient"
                            field="REQUEST_EMPLOYEE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='REQUEST_EMPLOYEE'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "REQUEST_HOMEROOM":
                    yield (
                        <Column
                            key="REQUEST_HOMEROOM"
                            className="md-column column"
                            header="Request Recipient Homeroom"
                            field="REQUEST_HOMEROOM"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='REQUEST_HOMEROOM'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "RATING":
                    yield (
                        <Column
                            key="RATING"
                            className="md-column column"
                            header="Rating"
                            field="RATING"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='RATING'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "DIRECTION":
                    yield (
                        <Column
                            key="DIRECTION"
                            className="md-column column"
                            header="Direction"
                            field="DIRECTION"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='DIRECTION'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                            body={value => toTitleCase(value.DIRECTION)}
                        ></Column>
                    );
                    break;
                case "DATE":
                    yield (
                        <Column
                            key="DATE"
                            className="md-column column"
                            header="Date"
                            field="DATE"
                            sortable
                            body={value => new Date(value.DATE).toLocaleDateString("en-US")}
                        ></Column>
                    );
                    break;
                case "COMPLIANT_PERCENT":
                    yield (
                        <Column
                            key="COMPLIANT_PERCENT"
                            className="md-column column"
                            dataType="numeric"
                            field="COMPLIANT_PERCENT"
                            // filter
                            showFilterMatchModes={false}
                            showFilterMenu={true}
                            filterClear="false"
                            filterField="COMPLIANT_PERCENT"
                            filterElement={sliderFilterTemplate}
                            header="Compliant Percent"
                            sortable
                            body={value => `${value.COMPLIANT_PERCENT}%`}>
                        </Column>
                    );
                    break;
                case "REQUEST_STATUS":
                    yield (
                        <Column
                            key="REQUEST_STATUS"
                            className="md-column column"
                            header="Request Status"
                            field="REQUEST_STATUS"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='REQUEST_STATUS'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "JOB_STATUS":
                    yield (
                        <Column
                            key="JOB_STATUS"
                            className="md-column column"
                            header="Job Status"
                            field="JOB_STATUS"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='JOB_STATUS'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "STAFF_TYPE":
                    yield (
                        <Column
                            key="STAFF_TYPE"
                            className="md-column column"
                            header="Staff Type"
                            field="STAFF_TYPE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='STAFF_TYPE'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "WEEK":
                    yield (
                        <Column
                            key="WEEK"
                            className="lg-column column"
                            header="Week"
                            field="WEEK"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='WEEK'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "ADMIN":
                    yield (
                        <Column
                            key="ADMIN"
                            className="lg-column column"
                            header="My administrative load is manageable"
                            field="ADMIN"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='ADMIN'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "PERSONAL":
                    yield (
                        <Column
                            key="PERSONAL"
                            className="lg-column column"
                            header="I have a personal distraction that is affecting my work this week"
                            field="PERSONAL"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='PERSONAL'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "SCHEDULE":
                    yield (
                        <Column
                            key="SCHEDULE"
                            className="lg-column column"
                            header="The amount of work I have on my schedule is reasonable"
                            field="SCHEDULE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='SCHEDULE'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "TEAM":
                    yield (
                        <Column
                            key="TEAM"
                            className="lg-column column"
                            header="I enjoy the people that I am working with this week"
                            field="TEAM"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='TEAM'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "STAFFNAME":
                    yield (
                        <Column
                            key="STAFFNAME"
                            className="lg-column column"
                            header="Staff Name"
                            field="STAFFNAME"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='STAFFNAME'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "STAFF_NAME":
                    yield (
                        <Column
                            key="STAFF_NAME"
                            className="lg-column column"
                            header="Staff Name"
                            field="STAFF_NAME"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='STAFF_NAME'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "EMPLOYEE":
                    yield (
                        <Column
                            key="EMPLOYEE"
                            className="lg-column column"
                            header="Staff Name"
                            field="EMPLOYEE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='EMPLOYEE'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "LEVEL":
                    yield (
                        <Column
                            key="LEVEL"
                            className="lg-column column"
                            header="Staff Level"
                            field="LEVEL"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='LEVEL'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "COMPLIANT_RANGE":
                    yield (
                        <Column
                            key="COMPLIANT_RANGE"
                            className="lg-column column"
                            header="Compliance Range"
                            field="COMPLIANT_RANGE"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='COMPLIANT_RANGE'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "DEPARTMENT":
                    yield (
                        <Column
                            key="DEPARTMENT"
                            className="lg-column column"
                            header="Staff Department"
                            field="DEPARTMENT"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='DEPARTMENT'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "HOMEROOM":
                    yield (
                        <Column
                            key="HOMEROOM"
                            className="lg-column column"
                            header="Staff Homeroom"
                            field="HOMEROOM"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='HOMEROOM'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "HOMEROOM_NAME":
                    yield (
                        <Column
                            key="HOMEROOM_NAME"
                            className="lg-column column"
                            header="Staff Homeroom"
                            field="HOMEROOM_NAME"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='HOMEROOM_NAME'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "CHECKER_NAME":
                    yield (
                        <Column
                            key="CHECKER_NAME"
                            className="lg-column column"
                            header="Homeroom Checker"
                            field="CHECKER_NAME"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='CHECKER_NAME'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                case "SEE_MORE":
                    yield (
                        <Column
                            key="SEE_MORE"
                            className="xl-column column"
                            header="Retain One"
                            field="SEE_MORE"
                            style={{ textWrap: 'wrap' }}
                        ></Column>
                    );
                    break;
                case "SEE_LESS":
                    yield (
                        <Column
                            key="SEE_LESS"
                            className="xl-column column"
                            header="Learn One"
                            field="SEE_LESS"
                            style={{ textWrap: 'wrap' }}
                        ></Column>
                    );
                    break;
                case "QUAD_GOAL":
                    yield (
                        <Column
                            key="QUAD_GOAL"
                            className="sm-column column"
                            header="Quad Goal"
                            field="QUAD_GOAL"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='QUAD_GOAL'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;

                case "MET_CURR_GOAL":
                    yield (
                        <Column
                            key="MET_CURR_GOAL"
                            className="sm-column column"
                            header="Met Current Goal"
                            field="MET_CURR_GOAL"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='MET_CURR_GOAL'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;

                case "CURRENT_QUAD_ROLOS":
                    yield (
                        <Column
                            key="CURRENT_QUAD_ROLOS"
                            className="sm-column column"
                            header="Current Quad ROLOs"
                            field="CURRENT_QUAD_ROLOS"
                            sortable
                        ></Column>
                    );
                    break;

                case "PREVIOUS_GOAL":
                    yield (
                        <Column
                            key="PREVIOUS_GOAL"
                            className="sm-column column"
                            header="Previous Goal"
                            field="PREVIOUS_GOAL"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='PREVIOUS_GOAL'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;

                case "MET_PREV_GOAL":
                    yield (
                        <Column
                            key="MET_PREV_GOAL"
                            className="sm-column column"
                            header="Met Previous Goal"
                            field="MET_PREV_GOAL"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='MET_PREV_GOAL'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;

                case "PREV_QUAD_ROLOS":
                    yield (
                        <Column
                            key="PREV_QUAD_ROLOS"
                            className="sm-column column"
                            header="Previous Quad ROLOs"
                            field="PREV_QUAD_ROLOS"
                            sortable
                        ></Column>
                    );
                    break;

                case "STAFFLEVEL":
                    yield (
                        <Column
                            key="STAFFLEVEL"
                            className="md-column column"
                            header="Staff Level"
                            field="STAFFLEVEL"
                            sortable
                            showFilterMenu={false}
                            filterClear="false"
                            filter
                            filterField='STAFFLEVEL'
                            filterMatchMode="in"
                            filterElement={multiFilterTemplate}
                        ></Column>
                    );
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <section className="data-view-table">
            <DataTable
                ref={dt}
                columnResizeMode="expand"
                resizableColumns
                reorderableColumns

                onValueChange={filteredData => {
                    onChange(filteredData);
                    currentData.current = filteredData;
                }}

                filters={filters}
                onFilter={(e) => setFilters(e.filters)}

                value={data}
                tableStyle={{ minWidth: '50rem' }}
                showGridlines
                stripedRows
                sortMode="multiple"
                filterDisplay="row"
                paginator rows={25}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                dataKey="INDEX"
                emptyMessage="No Data Found"
                scrollable
                scrollHeight={scrollHeight}
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                paginatorRight={paginatorRight}
            >
                {[...renderSwitch(columnList)]}
                {customColumn}

            </DataTable>
        </section>
    )
}