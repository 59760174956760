import React, { Fragment } from "react";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { useSelector } from "react-redux";

// import ArNav from "./navigation/ArNav";
// import WipNav from "./navigation/WipNav";
// import BillNav from "./navigation/BillNav";
import HrNav from "./navigation/HrNav";
import { TimeReportsNav, SelfTimeNav } from "./navigation/TimeNav";
// import PowerBINav from "./navigation/PowerBINav";
import "primeicons/primeicons.css";
import NCTONav from "./navigation/NCTONav";
import { HelpMarker } from "./Help";
/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */
export const PageLayout = ({ setShowHelp, children }) => {
    const user = useSelector(state => state.user)

    const { TOP_HR, TEAM_HR, FIRM_TIME } = user
    return (
        <>
            <Navbar
                expand="lg"
                variant="dark"
                className="navbarStyle primary-bg brand-text brand-gradient"
            >
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="/Magnify-21.png"
                            width="130px"
                            alt="Magnify logo on blue and white background, representing a brand with a sleek and modern design."
                        // height="10px"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link href="/" className="brand-text">
                                Home
                            </Nav.Link>
                            {TOP_HR === 1 || TEAM_HR === 1 ? <Dropdown variant="primary-bg brand-text">
                                <Dropdown.Toggle variant="primary-bg brand-text">
                                    Magnify Reports
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    {/* <ArNav />
                                    <WipNav />
                                    <BillNav /> */}
                                    <HrNav />
                                    {/* {FIRM_TIME === 1 ? <TimeReportsNav /> : <Fragment />} */}
                                    {/* <PowerBINav /> */}
                                </Dropdown.Menu>
                            </Dropdown> : <Fragment></Fragment>}
                            <Dropdown>
                                <Dropdown.Toggle variant="primary-bg brand-text">
                                    Magnify M+M
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/people/reviews">ROLOs</Dropdown.Item>
                                    <Dropdown.Item href="/people/requests">Requests</Dropdown.Item>
                                    <Dropdown.Item href='/people/morale'>Weekly Check In</Dropdown.Item>
                                    {/* <Dropdown.Item href="/people/morale">Homeroom Check in</Dropdown.Item> */}
                                </Dropdown.Menu>

                            </Dropdown>
                            {/* <Dropdown>
                    <Dropdown.Toggle variant="primary-bg brand-text">
                        Magnify Apps
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/apps/ncto">New Client Take On</Dropdown.Item>
                        <Dropdown.Item href="/apps/bingo">Bingo</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>  */}
                            <Dropdown>
                                <Dropdown.Toggle variant="primary-bg brand-text">
                                    Magnify Clients
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <NCTONav />
                                </Dropdown.Menu>
                            </Dropdown>
                            <Nav.Link href="/reports/time/self" className="brand-text">
                                Magnify TIME
                            </Nav.Link>
                        </Nav>
                        <HelpMarker
                            className={"help-button"}
                            icon="pi-question"
                            showCallBack={() => setShowHelp(true)}
                        >
                            <span>Help</span>
                        </HelpMarker>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {children}
        </>
    );
};
